import { render, staticRenderFns } from "./SobreNosotros.vue?vue&type=template&id=d6c98f22&"
import script from "./SobreNosotros.vue?vue&type=script&lang=js&"
export * from "./SobreNosotros.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports