<template>
  <div class="SobreNosotros">
    <section
      class="webpage__our-story our-story"
      style="margin-top:90px"
    >
      <div class="our-story__inner container">
        <div class="row align-items-center">
          <div class="our-story__intro col-12 col-md col-lg-7">
            <div class="our-story__image">
              <img
                src="@/assets/img/sobre-nosotros.png"
                width="851"
                height="777"
                alt=""
              >
              <div class="our-story__intro-wrapper">
                <div class="our-story__exp">
                  <span class="our-story__exp-value">70</span>
                  <span class="our-story__exp-text">años
                    <br>contigo
                  </span>
                </div>
                <div class="our-story__intro-text">Cruz Roja Mexicana Delegación Chihuahua</div>
              </div>
            </div>
          </div>
          <div class="our-story__content col-12 col-md col-lg-5">
            <h2 class="our-story__heading heading">Seamos todos hermanos</h2>
            <div class="our-story__text our-story__text--style-bolditalic">Una de las razones por las que la Cruz Roja se ha convertido en la orgazación que somos es debido a que conectamos con la gente y las comunidades en todo el país y en todo el mundo.</div>
            <div class="our-story__text">No sólo eso, la Cruz Roja junto a los lazos comunes de la humanidad y la compasión ha hecho que nos unamos,
              no sólo frente a las emergencias y desastres, sino también para ayudar a nuestros vecinos todos los días.</div>
          </div>
        </div>
        <div class="our-story__statistics statistics row">
          <div class="statistics__item col-12 col-sm">
            <div class="statistics__item-value">
              <span
                class="__js_number"
                data-end-value="100"
              >387K</span>
            </div>
            <div class="statistics__item-text">pacientes<br>atendidos
            </div>
          </div>
          <div class="statistics__item col-12 col-sm">
            <div class="statistics__item-value">
              <span
                class="__js_number"
                data-end-value="250"
              >50</span>
            </div>
            <div class="statistics__item-text">emergencias
              <br>diarias
            </div>
          </div>
          <div class="statistics__item col-12 col-lg">
            <div class="statistics__item-value">
              <span
                class="__js_number"
                data-end-value="3875"
              >168</span>
            </div>
            <div class="statistics__item-text">voluntarios
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="webpage__type-service type-service">
      <div class="container">
        <h2 class="type-service__heading heading">¿Quienes somos?</h2>
        <p class="">El Movimiento Internacional de la Cruz Roja y de la Media Luna Roja es la red humanitaria más grande del mundo, cuya misión es prevenir y aliviar, en todas las circunstancias, el sufrimiento humano; proteger la vida y la salud, y hacer respetar a la persona humana, en particular en tiempo de conflicto armado y en otras situaciones de urgencia.</p>
        <br><br>
        <h2 class="type-service__heading heading mt24">Nuestra historia</h2>
        <p>Jean Henry Dunant es el fundador del Movimiento Internacional de la Cruz Roja y de la Media Luna Roja. Nació el 8 de Mayo de 1828 en Ginebra Suiza.
          Testigo de la Batalla de Solferino, el 24 de Junio de 1859, donde se esfuerza por improvisar asistencia y auxilio para los combatientes heridos.
          Recibe el primer Premio Nobel de la Paz en Diciembre de 1901.
          La Cruz Roja Internacional actuó formalmente como resultado de la Conferencia Internacional de Ginebra, reunida en octubre de 1863, planteando la necesidad de establecer convenciones que permitieran auxiliar a los heridos en los campos de batalla y dar carácter neutral a los cuerpos encargados de prestarles socorro.
          Se escogió un símbolo idéntico para que todos los países distinguieran sus cuerpos de personal sanitario, hospitales y ambulancias.
          La Conferencia escogió una bandera con campo blanco y una Cruz Roja en el centro, inversión del pabellón suizo, en homenaje a la nacionalidad de Henry Dunant.</p>

      </div>
    </section>
    <section class="webpage__rewards rewards">
      <div class="rewards__inner container">
        <header class="rewards__header">
          <h2 class="rewards__heading heading">Principios fundamentales que nos guían</h2>
        </header>
        <ul class="service-page__process process-step">
          <li
            class="process-step__item"
            v-for="(p,i) in principios"
            :key="i"
          >
            <div class="process-step__item-title">{{p.name}}</div>
            <div class="process-step__item-text">{{p.content}}</div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "SobreNosotros",
  data() {
    return {
      principios: [
        {
          name: "Humanidad",
          content:
            "El Movimiento Internacional de la Cruz Roja y de la Media Luna Roja, al que ha dado nacimiento la preocupación de prestar auxilio sin discriminación, a todos los heridos en los campos de batalla, se esfuerza bajo su aspecto internacional y nacional, en prevenir y aliviar el sufrimiento de los hombres en todas circunstancias, tiende a proteger la vida y la salud, así como hacer respetar a la persona humana, favorece la comprensión mutua, la amistad, la cooperación y una paz duradera entre todos los pueblos.",
        },
        {
          name: "Imparcialidad",
          content:
            "No hace ninguna distinción de nacionalidad, raza, religión, condición social, ni credo político, se dedica únicamente a socorrer a los individuos en proporción con los sufrimientos, remediar sus necesidades y dando prioridad a las más urgentes.",
        },
        {
          name: "Neutralidad",
          content:
            "Con el fin de conservar la confianza de todos, el Movimiento se abstiene de tomar parte en las hostilidades y, en todo tiempo, en las controversias de orden político, racial, religioso e ideológico.",
        },
        {
          name: "Independencia",
          content:
            "El Movimiento es independiente. Auxiliares de los poderes públicos en sus actividades humanitarias y sometidas a las leyes que rigen los países respectivos las Sociedades Nacionales deben, sin embargo, conservar una autonomía que les permita actuar siempre de acuerdo con los Principios del Movimiento.",
        },
        {
          name: "Voluntariado",
          content:
            "Es un movimiento de socorro voluntario y de carácter desinteresado.",
        },
        {
          name: "Unidad",
          content:
            "En cada país, sólo puede existir una sola Sociedad de la Cruz Roja o de la Media Luna Roja, debe ser accesible a todos y extender su acción humanitaria a la totalidad del territorio.",
        },
        {
          name: "Universalidad",
          content:
            "El Movimiento Internacional de la Cruz Roja y de la Media Luna Roja, en cuyo seno todas las sociedades tienen los mismos derechos y el deber de ayudarse mutuamente, es universal.",
        },
      ],
    };
  },
};
</script>